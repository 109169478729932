import { default as basistarievenmdnhWW1MX1Meta } from "/dia/opus/pages/acquisitie/basistarieven.vue?macro=true";
import { default as domeinenWW9yRAcx4LMeta } from "/dia/opus/pages/acquisitie/domeinen.vue?macro=true";
import { default as indexManulfTfBnMeta } from "/dia/opus/pages/acquisitie/index.vue?macro=true";
import { default as raamovereenkomsteneemlU6XCcXMeta } from "/dia/opus/pages/acquisitie/raamovereenkomsten.vue?macro=true";
import { default as liquiditeitRl2G4kAmusMeta } from "/dia/opus/pages/administratie/[jaar]/liquiditeit.vue?macro=true";
import { default as overloopMluUlVvivlMeta } from "/dia/opus/pages/administratie/[jaar]/overloop.vue?macro=true";
import { default as actieshhLaTgJGfnMeta } from "/dia/opus/pages/administratie/acties.vue?macro=true";
import { default as afrondingXHaECBmGQVMeta } from "/dia/opus/pages/administratie/afronding.vue?macro=true";
import { default as _91jaar_93dI4RVYVJZzMeta } from "/dia/opus/pages/administratie/betaling/[jaar].vue?macro=true";
import { default as borgtochtenXmLtY8YYwKMeta } from "/dia/opus/pages/administratie/borgtochten.vue?macro=true";
import { default as indexja9XDRV4bmMeta } from "/dia/opus/pages/administratie/declaraties/index.vue?macro=true";
import { default as indexlK1FwjBsY9Meta } from "/dia/opus/pages/administratie/index.vue?macro=true";
import { default as _91jaar_93oPEwDzii54Meta } from "/dia/opus/pages/administratie/inkoop/[jaar].vue?macro=true";
import { default as _91jaar_93Z3VpyaJt8FMeta } from "/dia/opus/pages/administratie/inkoopfacturen/[jaar].vue?macro=true";
import { default as issuesyFacd8JEqdMeta } from "/dia/opus/pages/administratie/issues.vue?macro=true";
import { default as medewerkersWAGxZAz2AyMeta } from "/dia/opus/pages/administratie/medewerkers.vue?macro=true";
import { default as overheveleni70YQ3kQ8GMeta } from "/dia/opus/pages/administratie/overhevelen.vue?macro=true";
import { default as _91budgetcode_939km34qel0YMeta } from "/dia/opus/pages/administratie/overtollig/[budgetcode].vue?macro=true";
import { default as teveelbetaaldx1hzrB3bA5Meta } from "/dia/opus/pages/administratie/teveelbetaald.vue?macro=true";
import { default as teverzendenfacturenwBeyZg2rAdMeta } from "/dia/opus/pages/administratie/teverzendenfacturen.vue?macro=true";
import { default as _91jaar_93tPJtQJqZ3eMeta } from "/dia/opus/pages/administratie/verantwoording/[jaar].vue?macro=true";
import { default as basistarievenKuvP6UeEeFMeta } from "/dia/opus/pages/basistarieven.vue?macro=true";
import { default as _91medewerker_93zQ0kYb8cxvMeta } from "/dia/opus/pages/beloning/[jaar]/[medewerker].vue?macro=true";
import { default as bonus3NVPUpbabYMeta } from "/dia/opus/pages/beloning/[jaar]/bonus.vue?macro=true";
import { default as eindejaarsmutatietD6AseXB0NMeta } from "/dia/opus/pages/beloning/[jaar]/eindejaarsmutatie.vue?macro=true";
import { default as mutatiesLxsKzhjsbFMeta } from "/dia/opus/pages/beloning/[jaar]/mutaties.vue?macro=true";
import { default as afsprakenc19jfALPFnMeta } from "/dia/opus/pages/beloning/afspraken.vue?macro=true";
import { default as indexBymFV44ySmMeta } from "/dia/opus/pages/beloning/index.vue?macro=true";
import { default as inflatie8wm4WwCBIkMeta } from "/dia/opus/pages/beloning/inflatie.vue?macro=true";
import { default as loonmVZmBiWNNWMeta } from "/dia/opus/pages/beloning/loon.vue?macro=true";
import { default as ontbrekendL0mlAPnA9lMeta } from "/dia/opus/pages/beloning/ontbrekend.vue?macro=true";
import { default as ontwikkeling9XWX0NdZFTMeta } from "/dia/opus/pages/beloning/ontwikkeling.vue?macro=true";
import { default as _91budgetcode_93SFeDgLZytGMeta } from "/dia/opus/pages/budget/[budgetcode].vue?macro=true";
import { default as index8WXXMwgUyrMeta } from "/dia/opus/pages/budget/index.vue?macro=true";
import { default as adreslijstVWfSXcJkMrMeta } from "/dia/opus/pages/communicatie/adreslijst.vue?macro=true";
import { default as indexV05hnWYjttMeta } from "/dia/opus/pages/communicatie/index.vue?macro=true";
import { default as todoTxkFq3ugCZMeta } from "/dia/opus/pages/communicatie/todo.vue?macro=true";
import { default as _91nummer_93yEoNEVfljRMeta } from "/dia/opus/pages/crediteur/[nummer].vue?macro=true";
import { default as indexFOcxkfw0pJMeta } from "/dia/opus/pages/crediteur/index.vue?macro=true";
import { default as _91nummer_93CstFzcuXUZMeta } from "/dia/opus/pages/debiteur/[nummer].vue?macro=true";
import { default as indexUThL2vKeLxMeta } from "/dia/opus/pages/debiteur/index.vue?macro=true";
import { default as indexz1Mpzu6QvVMeta } from "/dia/opus/pages/factuur/[nummer]/index.vue?macro=true";
import { default as opmakenPCY7IJaM0BMeta } from "/dia/opus/pages/factuur/[nummer]/opmaken.vue?macro=true";
import { default as gepland_45verlofS9vuQe6hMhMeta } from "/dia/opus/pages/gepland-verlof.vue?macro=true";
import { default as _91medewerker_93GPq57xjIpHMeta } from "/dia/opus/pages/hrm/dienstverbanden/[medewerker].vue?macro=true";
import { default as diversiteitvOPw9QEJBuMeta } from "/dia/opus/pages/hrm/diversiteit.vue?macro=true";
import { default as doorgroeilTw1jOcVwKMeta } from "/dia/opus/pages/hrm/doorgroei.vue?macro=true";
import { default as ervaringL2IeJavk0BMeta } from "/dia/opus/pages/hrm/ervaring.vue?macro=true";
import { default as _91jaar_933JNO69rJu6Meta } from "/dia/opus/pages/hrm/feestdagen/[jaar].vue?macro=true";
import { default as functiesAksOWhH5djMeta } from "/dia/opus/pages/hrm/functies.vue?macro=true";
import { default as gepland_45verloffZ4VyEKimUMeta } from "/dia/opus/pages/hrm/gepland-verlof.vue?macro=true";
import { default as indexmU4NmjWXRKMeta } from "/dia/opus/pages/hrm/index.vue?macro=true";
import { default as medewerkersCga0iVhDoCMeta } from "/dia/opus/pages/hrm/medewerkers.vue?macro=true";
import { default as meldingenpuQkhsdUQ5Meta } from "/dia/opus/pages/hrm/meldingen.vue?macro=true";
import { default as mutaties1s88quNBlMMeta } from "/dia/opus/pages/hrm/mutaties.vue?macro=true";
import { default as ovabonnementenJwwwH5qKMmMeta } from "/dia/opus/pages/hrm/ovabonnementen.vue?macro=true";
import { default as _91jaar_936euSYZaSLxMeta } from "/dia/opus/pages/hrm/overwerk/[jaar].vue?macro=true";
import { default as retentieFcFtbVyBmJMeta } from "/dia/opus/pages/hrm/retentie.vue?macro=true";
import { default as samenwerkingtWHwQ6NibCMeta } from "/dia/opus/pages/hrm/samenwerking.vue?macro=true";
import { default as _91jaar_93RDeHffuSi8Meta } from "/dia/opus/pages/hrm/verlof/[jaar].vue?macro=true";
import { default as verlofwijzigingenezvjgdHem9Meta } from "/dia/opus/pages/hrm/verlofwijzigingen.vue?macro=true";
import { default as vliegurennY8l9oeLu0Meta } from "/dia/opus/pages/hrm/vlieguren.vue?macro=true";
import { default as watervalkXP7fscdCJMeta } from "/dia/opus/pages/hrm/waterval.vue?macro=true";
import { default as _91jaar_93QuBPQ6Bti5Meta } from "/dia/opus/pages/hrm/ziekte/[jaar].vue?macro=true";
import { default as accountsaW9XCFKzZxMeta } from "/dia/opus/pages/ict/accounts.vue?macro=true";
import { default as archiveringJheAzJJgBWMeta } from "/dia/opus/pages/ict/archivering.vue?macro=true";
import { default as index4yx07udj5GMeta } from "/dia/opus/pages/ict/index.vue?macro=true";
import { default as privilegeswp8cESzXtGMeta } from "/dia/opus/pages/ict/privileges.vue?macro=true";
import { default as indexVpmIGlRg6AMeta } from "/dia/opus/pages/index.vue?macro=true";
import { default as _91nummer_938EA84hp9EAMeta } from "/dia/opus/pages/inkoop/[nummer].vue?macro=true";
import { default as uitstootU7Zl50anQ4Meta } from "/dia/opus/pages/kwaliteit/[jaar]/uitstoot.vue?macro=true";
import { default as evaluatieK5jIaXjMn2Meta } from "/dia/opus/pages/kwaliteit/evaluatie.vue?macro=true";
import { default as indexeMGvU552LgMeta } from "/dia/opus/pages/kwaliteit/index.vue?macro=true";
import { default as detailso6paEbylKjMeta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/[week]/details.vue?macro=true";
import { default as index8phri1hWtxMeta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/[week]/index.vue?macro=true";
import { default as indexoYk1H3FJCnMeta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/besteding/index.vue?macro=true";
import { default as vergelijking8qNWOowQRYMeta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/besteding/vergelijking.vue?macro=true";
import { default as declaratiesaQOPf1mj9xMeta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/declaraties.vue?macro=true";
import { default as feestdagen4uMhXwCDfvMeta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/feestdagen.vue?macro=true";
import { default as indexpUGxFxijjFMeta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/index.vue?macro=true";
import { default as verlofWgeU86NDL9Meta } from "/dia/opus/pages/medewerker/[medewerker]/[jaar]/verlof.vue?macro=true";
import { default as dienstverbandxgeRBEWjzYMeta } from "/dia/opus/pages/medewerker/[medewerker]/dienstverband.vue?macro=true";
import { default as gegevensq2fohjKY9oMeta } from "/dia/opus/pages/medewerker/[medewerker]/gegevens.vue?macro=true";
import { default as indexFrUkPDa99bMeta } from "/dia/opus/pages/medewerker/[medewerker]/index.vue?macro=true";
import { default as noodnummersydVtv8a6wlMeta } from "/dia/opus/pages/medewerker/[medewerker]/noodnummers.vue?macro=true";
import { default as planningc2kaoXBiWcMeta } from "/dia/opus/pages/medewerker/[medewerker]/planning.vue?macro=true";
import { default as projectenlijstGS48a5eXDYMeta } from "/dia/opus/pages/medewerker/[medewerker]/projectenlijst.vue?macro=true";
import { default as _91medewerker_93uNck5fb3ZEMeta } from "/dia/opus/pages/medewerker/[medewerker].vue?macro=true";
import { default as _91week_93UFELYWbG5OMeta } from "/dia/opus/pages/mt/[jaar]/[week].vue?macro=true";
import { default as inzetLqmhwQB7X3Meta } from "/dia/opus/pages/mt/[jaar]/inzet.vue?macro=true";
import { default as urenbestedingvvbQ2GfN0PMeta } from "/dia/opus/pages/mt/[jaar]/urenbesteding.vue?macro=true";
import { default as belastingIufzVKiZokMeta } from "/dia/opus/pages/mt/belasting.vue?macro=true";
import { default as dashboardW2C9XPKyVOMeta } from "/dia/opus/pages/mt/dashboard.vue?macro=true";
import { default as _91jaar_93RSMhNWFBX7Meta } from "/dia/opus/pages/mt/directpercentages/[jaar].vue?macro=true";
import { default as indexbJbbVRiM7OMeta } from "/dia/opus/pages/mt/index.vue?macro=true";
import { default as indirect_45historisch2FnnHsjWK1Meta } from "/dia/opus/pages/mt/indirect-historisch.vue?macro=true";
import { default as indirectbVAF7Wy0osMeta } from "/dia/opus/pages/mt/indirect.vue?macro=true";
import { default as langetermijngV8WZaMn6EMeta } from "/dia/opus/pages/mt/langetermijn.vue?macro=true";
import { default as overheadTrkBym9sNzMeta } from "/dia/opus/pages/mt/overhead.vue?macro=true";
import { default as projecten7Uz7m7D9pJMeta } from "/dia/opus/pages/mt/projecten.vue?macro=true";
import { default as tariefontwikkelingfOKFutbl7XMeta } from "/dia/opus/pages/mt/tariefontwikkeling.vue?macro=true";
import { default as _91offertenummer_93FieUqHakPAMeta } from "/dia/opus/pages/offerte/[offertenummer].vue?macro=true";
import { default as index12C7nBtlUmMeta } from "/dia/opus/pages/offerte/index.vue?macro=true";
import { default as prestaties8uS4ElOyqwMeta } from "/dia/opus/pages/prestaties.vue?macro=true";
import { default as index6E5t37H6cdMeta } from "/dia/opus/pages/resultaat/index.vue?macro=true";
import { default as meerjarigNbRJLlPqgIMeta } from "/dia/opus/pages/resultaat/meerjarig.vue?macro=true";
import { default as _91jaar_93stozYZDFNaMeta } from "/dia/opus/pages/resultaat/multiplier/[jaar].vue?macro=true";
import { default as indexfylUGsPukCMeta } from "/dia/opus/pages/resultaat/multiplier/index.vue?macro=true";
import { default as _91jaar_93hOMuTL0horMeta } from "/dia/opus/pages/resultaat/projectie/[jaar].vue?macro=true";
import { default as _91jaar_93MGJxiDotVLMeta } from "/dia/opus/pages/resultaat/projectwinst/[jaar].vue?macro=true";
import { default as _91jaar_93QBsw15uyCsMeta } from "/dia/opus/pages/resultaat/simulatie/[jaar].vue?macro=true";
import { default as telefoonboeks1McXFEfThMeta } from "/dia/opus/pages/telefoonboek.vue?macro=true";
import { default as verjaardagenoly3HPl9FDMeta } from "/dia/opus/pages/verjaardagen.vue?macro=true";
import { default as werkvoorraad87IPNE7aWAMeta } from "/dia/opus/pages/werkvoorraad.vue?macro=true";
import { default as zoekenevE5Lzyi73Meta } from "/dia/opus/pages/zoeken.vue?macro=true";
export default [
  {
    name: "acquisitie-basistarieven",
    path: "/acquisitie/basistarieven",
    component: () => import("/dia/opus/pages/acquisitie/basistarieven.vue")
  },
  {
    name: "acquisitie-domeinen",
    path: "/acquisitie/domeinen",
    component: () => import("/dia/opus/pages/acquisitie/domeinen.vue")
  },
  {
    name: "acquisitie",
    path: "/acquisitie",
    component: () => import("/dia/opus/pages/acquisitie/index.vue")
  },
  {
    name: "acquisitie-raamovereenkomsten",
    path: "/acquisitie/raamovereenkomsten",
    component: () => import("/dia/opus/pages/acquisitie/raamovereenkomsten.vue")
  },
  {
    name: "administratie-jaar-liquiditeit",
    path: "/administratie/:jaar()/liquiditeit",
    component: () => import("/dia/opus/pages/administratie/[jaar]/liquiditeit.vue")
  },
  {
    name: "administratie-jaar-overloop",
    path: "/administratie/:jaar()/overloop",
    component: () => import("/dia/opus/pages/administratie/[jaar]/overloop.vue")
  },
  {
    name: "administratie-acties",
    path: "/administratie/acties",
    component: () => import("/dia/opus/pages/administratie/acties.vue")
  },
  {
    name: "administratie-afronding",
    path: "/administratie/afronding",
    component: () => import("/dia/opus/pages/administratie/afronding.vue")
  },
  {
    name: "administratie-betaling-jaar",
    path: "/administratie/betaling/:jaar()",
    component: () => import("/dia/opus/pages/administratie/betaling/[jaar].vue")
  },
  {
    name: "administratie-borgtochten",
    path: "/administratie/borgtochten",
    component: () => import("/dia/opus/pages/administratie/borgtochten.vue")
  },
  {
    name: "administratie-declaraties",
    path: "/administratie/declaraties",
    component: () => import("/dia/opus/pages/administratie/declaraties/index.vue")
  },
  {
    name: "administratie",
    path: "/administratie",
    component: () => import("/dia/opus/pages/administratie/index.vue")
  },
  {
    name: "administratie-inkoop-jaar",
    path: "/administratie/inkoop/:jaar()",
    component: () => import("/dia/opus/pages/administratie/inkoop/[jaar].vue")
  },
  {
    name: "administratie-inkoopfacturen-jaar",
    path: "/administratie/inkoopfacturen/:jaar()",
    component: () => import("/dia/opus/pages/administratie/inkoopfacturen/[jaar].vue")
  },
  {
    name: "administratie-issues",
    path: "/administratie/issues",
    component: () => import("/dia/opus/pages/administratie/issues.vue")
  },
  {
    name: "administratie-medewerkers",
    path: "/administratie/medewerkers",
    component: () => import("/dia/opus/pages/administratie/medewerkers.vue")
  },
  {
    name: "administratie-overhevelen",
    path: "/administratie/overhevelen",
    component: () => import("/dia/opus/pages/administratie/overhevelen.vue")
  },
  {
    name: "administratie-overtollig-budgetcode",
    path: "/administratie/overtollig/:budgetcode()",
    component: () => import("/dia/opus/pages/administratie/overtollig/[budgetcode].vue")
  },
  {
    name: "administratie-teveelbetaald",
    path: "/administratie/teveelbetaald",
    component: () => import("/dia/opus/pages/administratie/teveelbetaald.vue")
  },
  {
    name: "administratie-teverzendenfacturen",
    path: "/administratie/teverzendenfacturen",
    component: () => import("/dia/opus/pages/administratie/teverzendenfacturen.vue")
  },
  {
    name: "administratie-verantwoording-jaar",
    path: "/administratie/verantwoording/:jaar()",
    component: () => import("/dia/opus/pages/administratie/verantwoording/[jaar].vue")
  },
  {
    name: "basistarieven",
    path: "/basistarieven",
    component: () => import("/dia/opus/pages/basistarieven.vue")
  },
  {
    name: "beloning-jaar-medewerker",
    path: "/beloning/:jaar()/:medewerker()",
    component: () => import("/dia/opus/pages/beloning/[jaar]/[medewerker].vue")
  },
  {
    name: "beloning-jaar-bonus",
    path: "/beloning/:jaar()/bonus",
    component: () => import("/dia/opus/pages/beloning/[jaar]/bonus.vue")
  },
  {
    name: "beloning-jaar-eindejaarsmutatie",
    path: "/beloning/:jaar()/eindejaarsmutatie",
    component: () => import("/dia/opus/pages/beloning/[jaar]/eindejaarsmutatie.vue")
  },
  {
    name: "beloning-jaar-mutaties",
    path: "/beloning/:jaar()/mutaties",
    component: () => import("/dia/opus/pages/beloning/[jaar]/mutaties.vue")
  },
  {
    name: "beloning-afspraken",
    path: "/beloning/afspraken",
    component: () => import("/dia/opus/pages/beloning/afspraken.vue")
  },
  {
    name: "beloning",
    path: "/beloning",
    component: () => import("/dia/opus/pages/beloning/index.vue")
  },
  {
    name: "beloning-inflatie",
    path: "/beloning/inflatie",
    component: () => import("/dia/opus/pages/beloning/inflatie.vue")
  },
  {
    name: "beloning-loon",
    path: "/beloning/loon",
    component: () => import("/dia/opus/pages/beloning/loon.vue")
  },
  {
    name: "beloning-ontbrekend",
    path: "/beloning/ontbrekend",
    component: () => import("/dia/opus/pages/beloning/ontbrekend.vue")
  },
  {
    name: "beloning-ontwikkeling",
    path: "/beloning/ontwikkeling",
    component: () => import("/dia/opus/pages/beloning/ontwikkeling.vue")
  },
  {
    name: "budget-budgetcode",
    path: "/budget/:budgetcode()",
    component: () => import("/dia/opus/pages/budget/[budgetcode].vue")
  },
  {
    name: "budget",
    path: "/budget",
    component: () => import("/dia/opus/pages/budget/index.vue")
  },
  {
    name: "communicatie-adreslijst",
    path: "/communicatie/adreslijst",
    component: () => import("/dia/opus/pages/communicatie/adreslijst.vue")
  },
  {
    name: "communicatie",
    path: "/communicatie",
    component: () => import("/dia/opus/pages/communicatie/index.vue")
  },
  {
    name: "communicatie-todo",
    path: "/communicatie/todo",
    component: () => import("/dia/opus/pages/communicatie/todo.vue")
  },
  {
    name: "crediteur-nummer",
    path: "/crediteur/:nummer()",
    component: () => import("/dia/opus/pages/crediteur/[nummer].vue")
  },
  {
    name: "crediteur",
    path: "/crediteur",
    component: () => import("/dia/opus/pages/crediteur/index.vue")
  },
  {
    name: "debiteur-nummer",
    path: "/debiteur/:nummer()",
    component: () => import("/dia/opus/pages/debiteur/[nummer].vue")
  },
  {
    name: "debiteur",
    path: "/debiteur",
    component: () => import("/dia/opus/pages/debiteur/index.vue")
  },
  {
    name: "factuur-nummer",
    path: "/factuur/:nummer()",
    component: () => import("/dia/opus/pages/factuur/[nummer]/index.vue")
  },
  {
    name: "factuur-nummer-opmaken",
    path: "/factuur/:nummer()/opmaken",
    component: () => import("/dia/opus/pages/factuur/[nummer]/opmaken.vue")
  },
  {
    name: "gepland-verlof",
    path: "/gepland-verlof",
    component: () => import("/dia/opus/pages/gepland-verlof.vue")
  },
  {
    name: "hrm-dienstverbanden-medewerker",
    path: "/hrm/dienstverbanden/:medewerker()",
    component: () => import("/dia/opus/pages/hrm/dienstverbanden/[medewerker].vue")
  },
  {
    name: "hrm-diversiteit",
    path: "/hrm/diversiteit",
    component: () => import("/dia/opus/pages/hrm/diversiteit.vue")
  },
  {
    name: "hrm-doorgroei",
    path: "/hrm/doorgroei",
    component: () => import("/dia/opus/pages/hrm/doorgroei.vue")
  },
  {
    name: "hrm-ervaring",
    path: "/hrm/ervaring",
    component: () => import("/dia/opus/pages/hrm/ervaring.vue")
  },
  {
    name: "hrm-feestdagen-jaar",
    path: "/hrm/feestdagen/:jaar()",
    component: () => import("/dia/opus/pages/hrm/feestdagen/[jaar].vue")
  },
  {
    name: "hrm-functies",
    path: "/hrm/functies",
    component: () => import("/dia/opus/pages/hrm/functies.vue")
  },
  {
    name: "hrm-gepland-verlof",
    path: "/hrm/gepland-verlof",
    component: () => import("/dia/opus/pages/hrm/gepland-verlof.vue")
  },
  {
    name: "hrm",
    path: "/hrm",
    component: () => import("/dia/opus/pages/hrm/index.vue")
  },
  {
    name: "hrm-medewerkers",
    path: "/hrm/medewerkers",
    component: () => import("/dia/opus/pages/hrm/medewerkers.vue")
  },
  {
    name: "hrm-meldingen",
    path: "/hrm/meldingen",
    component: () => import("/dia/opus/pages/hrm/meldingen.vue")
  },
  {
    name: "hrm-mutaties",
    path: "/hrm/mutaties",
    component: () => import("/dia/opus/pages/hrm/mutaties.vue")
  },
  {
    name: "hrm-ovabonnementen",
    path: "/hrm/ovabonnementen",
    component: () => import("/dia/opus/pages/hrm/ovabonnementen.vue")
  },
  {
    name: "hrm-overwerk-jaar",
    path: "/hrm/overwerk/:jaar()",
    component: () => import("/dia/opus/pages/hrm/overwerk/[jaar].vue")
  },
  {
    name: "hrm-retentie",
    path: "/hrm/retentie",
    component: () => import("/dia/opus/pages/hrm/retentie.vue")
  },
  {
    name: "hrm-samenwerking",
    path: "/hrm/samenwerking",
    component: () => import("/dia/opus/pages/hrm/samenwerking.vue")
  },
  {
    name: "hrm-verlof-jaar",
    path: "/hrm/verlof/:jaar()",
    component: () => import("/dia/opus/pages/hrm/verlof/[jaar].vue")
  },
  {
    name: "hrm-verlofwijzigingen",
    path: "/hrm/verlofwijzigingen",
    component: () => import("/dia/opus/pages/hrm/verlofwijzigingen.vue")
  },
  {
    name: "hrm-vlieguren",
    path: "/hrm/vlieguren",
    component: () => import("/dia/opus/pages/hrm/vlieguren.vue")
  },
  {
    name: "hrm-waterval",
    path: "/hrm/waterval",
    component: () => import("/dia/opus/pages/hrm/waterval.vue")
  },
  {
    name: "hrm-ziekte-jaar",
    path: "/hrm/ziekte/:jaar()",
    component: () => import("/dia/opus/pages/hrm/ziekte/[jaar].vue")
  },
  {
    name: "ict-accounts",
    path: "/ict/accounts",
    component: () => import("/dia/opus/pages/ict/accounts.vue")
  },
  {
    name: "ict-archivering",
    path: "/ict/archivering",
    component: () => import("/dia/opus/pages/ict/archivering.vue")
  },
  {
    name: "ict",
    path: "/ict",
    component: () => import("/dia/opus/pages/ict/index.vue")
  },
  {
    name: "ict-privileges",
    path: "/ict/privileges",
    component: () => import("/dia/opus/pages/ict/privileges.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/dia/opus/pages/index.vue")
  },
  {
    name: "inkoop-nummer",
    path: "/inkoop/:nummer()",
    component: () => import("/dia/opus/pages/inkoop/[nummer].vue")
  },
  {
    name: "kwaliteit-jaar-uitstoot",
    path: "/kwaliteit/:jaar()/uitstoot",
    component: () => import("/dia/opus/pages/kwaliteit/[jaar]/uitstoot.vue")
  },
  {
    name: "kwaliteit-evaluatie",
    path: "/kwaliteit/evaluatie",
    component: () => import("/dia/opus/pages/kwaliteit/evaluatie.vue")
  },
  {
    name: "kwaliteit",
    path: "/kwaliteit",
    component: () => import("/dia/opus/pages/kwaliteit/index.vue")
  },
  {
    name: _91medewerker_93uNck5fb3ZEMeta?.name,
    path: "/medewerker/:medewerker()",
    component: () => import("/dia/opus/pages/medewerker/[medewerker].vue"),
    children: [
  {
    name: "medewerker-medewerker-jaar-week-details",
    path: ":jaar()/:week()/details",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/[week]/details.vue")
  },
  {
    name: "medewerker-medewerker-jaar-week",
    path: ":jaar()/:week()",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/[week]/index.vue")
  },
  {
    name: "medewerker-medewerker-jaar-besteding",
    path: ":jaar()/besteding",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/besteding/index.vue")
  },
  {
    name: "medewerker-medewerker-jaar-besteding-vergelijking",
    path: ":jaar()/besteding/vergelijking",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/besteding/vergelijking.vue")
  },
  {
    name: "medewerker-medewerker-jaar-declaraties",
    path: ":jaar()/declaraties",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/declaraties.vue")
  },
  {
    name: "medewerker-medewerker-jaar-feestdagen",
    path: ":jaar()/feestdagen",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/feestdagen.vue")
  },
  {
    name: "medewerker-medewerker-jaar",
    path: ":jaar()",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/index.vue")
  },
  {
    name: "medewerker-medewerker-jaar-verlof",
    path: ":jaar()/verlof",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/[jaar]/verlof.vue")
  },
  {
    name: "medewerker-medewerker-dienstverband",
    path: "dienstverband",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/dienstverband.vue")
  },
  {
    name: "medewerker-medewerker-gegevens",
    path: "gegevens",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/gegevens.vue")
  },
  {
    name: "medewerker-medewerker",
    path: "",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/index.vue")
  },
  {
    name: "medewerker-medewerker-noodnummers",
    path: "noodnummers",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/noodnummers.vue")
  },
  {
    name: "medewerker-medewerker-planning",
    path: "planning",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/planning.vue")
  },
  {
    name: "medewerker-medewerker-projectenlijst",
    path: "projectenlijst",
    component: () => import("/dia/opus/pages/medewerker/[medewerker]/projectenlijst.vue")
  }
]
  },
  {
    name: "mt-jaar-week",
    path: "/mt/:jaar()/:week()",
    component: () => import("/dia/opus/pages/mt/[jaar]/[week].vue")
  },
  {
    name: "mt-jaar-inzet",
    path: "/mt/:jaar()/inzet",
    component: () => import("/dia/opus/pages/mt/[jaar]/inzet.vue")
  },
  {
    name: "mt-jaar-urenbesteding",
    path: "/mt/:jaar()/urenbesteding",
    component: () => import("/dia/opus/pages/mt/[jaar]/urenbesteding.vue")
  },
  {
    name: "mt-belasting",
    path: "/mt/belasting",
    component: () => import("/dia/opus/pages/mt/belasting.vue")
  },
  {
    name: "mt-dashboard",
    path: "/mt/dashboard",
    component: () => import("/dia/opus/pages/mt/dashboard.vue")
  },
  {
    name: "mt-directpercentages-jaar",
    path: "/mt/directpercentages/:jaar()",
    component: () => import("/dia/opus/pages/mt/directpercentages/[jaar].vue")
  },
  {
    name: "mt",
    path: "/mt",
    component: () => import("/dia/opus/pages/mt/index.vue")
  },
  {
    name: "mt-indirect-historisch",
    path: "/mt/indirect-historisch",
    component: () => import("/dia/opus/pages/mt/indirect-historisch.vue")
  },
  {
    name: "mt-indirect",
    path: "/mt/indirect",
    component: () => import("/dia/opus/pages/mt/indirect.vue")
  },
  {
    name: "mt-langetermijn",
    path: "/mt/langetermijn",
    component: () => import("/dia/opus/pages/mt/langetermijn.vue")
  },
  {
    name: "mt-overhead",
    path: "/mt/overhead",
    component: () => import("/dia/opus/pages/mt/overhead.vue")
  },
  {
    name: "mt-projecten",
    path: "/mt/projecten",
    component: () => import("/dia/opus/pages/mt/projecten.vue")
  },
  {
    name: "mt-tariefontwikkeling",
    path: "/mt/tariefontwikkeling",
    component: () => import("/dia/opus/pages/mt/tariefontwikkeling.vue")
  },
  {
    name: "offerte-offertenummer",
    path: "/offerte/:offertenummer()",
    component: () => import("/dia/opus/pages/offerte/[offertenummer].vue")
  },
  {
    name: "offerte",
    path: "/offerte",
    component: () => import("/dia/opus/pages/offerte/index.vue")
  },
  {
    name: "prestaties",
    path: "/prestaties",
    component: () => import("/dia/opus/pages/prestaties.vue")
  },
  {
    name: "resultaat",
    path: "/resultaat",
    component: () => import("/dia/opus/pages/resultaat/index.vue")
  },
  {
    name: "resultaat-meerjarig",
    path: "/resultaat/meerjarig",
    component: () => import("/dia/opus/pages/resultaat/meerjarig.vue")
  },
  {
    name: "resultaat-multiplier-jaar",
    path: "/resultaat/multiplier/:jaar()",
    component: () => import("/dia/opus/pages/resultaat/multiplier/[jaar].vue")
  },
  {
    name: "resultaat-multiplier",
    path: "/resultaat/multiplier",
    component: () => import("/dia/opus/pages/resultaat/multiplier/index.vue")
  },
  {
    name: "resultaat-projectie-jaar",
    path: "/resultaat/projectie/:jaar()",
    component: () => import("/dia/opus/pages/resultaat/projectie/[jaar].vue")
  },
  {
    name: "resultaat-projectwinst-jaar",
    path: "/resultaat/projectwinst/:jaar()",
    component: () => import("/dia/opus/pages/resultaat/projectwinst/[jaar].vue")
  },
  {
    name: "resultaat-simulatie-jaar",
    path: "/resultaat/simulatie/:jaar()",
    component: () => import("/dia/opus/pages/resultaat/simulatie/[jaar].vue")
  },
  {
    name: "telefoonboek",
    path: "/telefoonboek",
    component: () => import("/dia/opus/pages/telefoonboek.vue")
  },
  {
    name: "verjaardagen",
    path: "/verjaardagen",
    component: () => import("/dia/opus/pages/verjaardagen.vue")
  },
  {
    name: "werkvoorraad",
    path: "/werkvoorraad",
    component: () => import("/dia/opus/pages/werkvoorraad.vue")
  },
  {
    name: "zoeken",
    path: "/zoeken",
    component: () => import("/dia/opus/pages/zoeken.vue")
  }
]